import React from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import ThemeContext from 'src/components/system_wide/theme_context'

import { Outlet } from 'react-router-dom'

const { t } = i18next

class DPApp extends React.Component {
  state = { ready: true }

  languages = {
    en: t('lang.en', 'English'),
    it: t('lang.it', 'Italian'),
    fr: t('lang.fr', 'French'),
    de: t('lang.de', 'German'),
  }

  changeLanguage = async language => {
    this.setState({ ready: false }, async () => {
      await this.props.i18n.changeLanguage(language)
      this.setState({ ready: true })
    })
  }

  render() {
    if (!this.state.ready) return
    return (
      <ThemeContext.Provider
        value={{
          languages: this.languages,
          current_language: this.props.i18n.language,
          changeLanguage: this.changeLanguage,
        }}>
        <main className="layout">
          <Outlet />
        </main>
      </ThemeContext.Provider>
    )
  }
}
export default withTranslation()(DPApp)
