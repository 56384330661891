import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Welcome from 'src/components/welcome'
import DPApp from 'src/components/dp_app'
import DigitalPassport from 'src/components/digital_passport'

export default function DpRoute() {
  return (
    <>
      <Routes>
        <Route element={<DPApp />}>
          <Route path="/" element={<Navigate to="welcome" />} />
          <Route path="/welcome/:rfid" element={<Welcome />} />
          <Route path="/digital-passport/:rfid" element={<DigitalPassport />} />
          <Route path="*" element={<Welcome />} />
        </Route>
      </Routes>
    </>
  )
}
