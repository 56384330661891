import React from 'react'

export const Logo = ({ className = '', description = '' }) => {
  return (
    <div className={`${className}`}>
      <img src="/logo-oscalito.png" className="img-fluid mb-3" alt={description} />
      <span>Made in Italy</span>
    </div>
  )
}
