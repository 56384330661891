import React from 'react'
import StyledSelect from 'src/components/system_wide/styled_select'
import ThemeContext from 'src/components/system_wide/theme_context'

class SelectLang extends React.Component {
  static contextType = ThemeContext
  static defaultProps = { maxWidth: '', type: 'select' }

  onChangeLanguage = async option => {
    await this.context.changeLanguage(option.value)
  }

  render() {
    let { languages, current_language } = this.context
    let { maxWidth, type } = this.props
    let languageOptions = Object.keys(languages).map(l => ({
      label: (
        <div>
          <span className={`fp ${l === 'en' ? 'gb' : l}`}></span>
          {languages[l]}
        </div>
      ),
      value: l,
    }))

    if (type === 'select') {
      return (
        <div style={{ width: maxWidth }}>
          <StyledSelect
            options={languageOptions}
            value={languageOptions.find(l => l.value === current_language)}
            onChange={this.onChangeLanguage}
          />
        </div>
      )
    }
    if (type === 'orizontal') {
      return (
        <div className="d-flex flex-row flex-wrap justify-content-center gap-2">
          {languageOptions.map(l => {
            let size = current_language === l.value ? 'large' : 'small'
            let px = size === 'large' ? '5rem' : '2.9rem'
            return (
              <span key={l.value} className="d-flex align-items-center" onClick={() => this.onChangeLanguage(l)}>
                <img src={`/${l.value}-${size}.png`} alt={`${l.value} lang flag`} style={{ width: px }} />
              </span>
            )
          })}
        </div>
      )
    }
  }
}

export default SelectLang
