import React from 'react'
import ReactSelect from 'react-select'
import ReactSelectCreatable from 'react-select/creatable'
import i18next from 'i18next'

const { t } = i18next

const selectStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      ...(state.selectProps.valid === false && { borderColor: 'var(--danger)' }),
      ...(state.selectProps.isDisabled === true && { backgroundColor: '#e9ecef' }),
      borderRadius: 'var(--bs-border-radius)',
      minHeight: '2rem',
    }
  },

  menu: styles => ({ ...styles, zIndex: 1000 }),
  menuPortal: styles => ({ ...styles, zIndex: 1000 }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    borderRadius: 'var(--bs-border-radius)',
  }),
  clearIndicator: styles => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
  dropdownIndicator: styles => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
}

const selectTheme = theme => {
  return {
    ...theme,
    borderRadius: 'var(--bs-border-radius)',
    colors: {
      ...theme.colors,
      primary25: '#e9ecef',
      primary: 'var(--bs-primary)',
    },
  }
}

class StyledSelect extends React.Component {
  static defaultProps = {
    styles: selectStyles,
    theme: selectTheme,
    name: '',
    value: '',
    onChange: () => {},
    options: {},
    isMulti: false,
    allowCreate: false,
    isDisabled: false,
  }

  render() {
    let { styles, theme, name, value, onChange, options, isMulti, isDisabled, allowCreate, ...rest } = this.props
    let Select = allowCreate ? ReactSelectCreatable : ReactSelect
    return (
      <>
        <style jsx="true" global="true">{`
          .styled_select__control--is-focused {
            border: solid 1px var(--primary-200) !important;
            box-shadow: 0 0 0 0.25rem var(--primary-100) !important;
          }
          .styled_select__control--is-disabled {
            border: solid 1px var(--bs-gray-400) !important;
          }
          .styled_select__menu > div {
            max-height: 11rem;
          }
          .styled_select--is-disabled {
            border-radius: var(--bs-border-radius) !important;
            border-color: var(--bs-gray-400) !important;
          }
        `}</style>
        <Select
          classNamePrefix="styled_select"
          styles={styles}
          theme={theme}
          id={name}
          isMulti={isMulti}
          isDisabled={isDisabled}
          instanceId={name}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          menuPlacement="auto"
          {...(allowCreate === true && {
            formatCreateLabel: input => `${t('styled_select.create_label', 'Create')} "${input}"`,
          })}
          {...rest}
        />
      </>
    )
  }
}

export default StyledSelect
