import { Button } from 'react-bootstrap'
import React from 'react'
import { Trans } from 'react-i18next'

class MoreText extends React.Component {
  static defaultProps = {
    text: '',
    line_truncate: 0,
  }
  state = {
    num_lines: 0,
    opened: false,
  }
  contentRef = null

  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
  }
  componentDidMount() {
    var style = window.getComputedStyle(this.contentRef?.current, null).getPropertyValue('font-size')
    var font_size = parseFloat(style)

    var line_height = Math.floor(font_size * 1.5)
    var height = this.contentRef?.current.clientHeight

    this.setState({ num_lines: Math.ceil(height / line_height) })
  }

  readMore = () => {
    this.setState({ opened: !this.state.opened })
  }

  render() {
    let { text, line_truncate } = this.props
    let { num_lines, opened } = this.state

    let classClamp = num_lines > line_truncate && opened === false ? 'line-clamp' : ''
    return (
      <>
        <style jsx="true">{`
          .description {
            -webkit-line-clamp: ${line_truncate};
            margin-bottom: 1rem;
          }
        `}</style>
        <div ref={this.contentRef} className={`description ${classClamp}`}>
          {text}
        </div>
        {num_lines > line_truncate && (
          <Button variant="link" style={{ color: '#766147', paddingLeft: 0 }} onClick={this.readMore}>
            {opened === false && (
              <>
                <Trans i18nKey="digital_passport.read_more">Read more</Trans>
                <i className="ms-4 fa-solid fa-circle-plus"></i>
              </>
            )}
            {opened === true && (
              <>
                <Trans i18nKey="digital_passport.read_less">Read less</Trans>
                <i className="ms-4 fa-solid fa-circle-minus"></i>
              </>
            )}
          </Button>
        )}
      </>
    )
  }
}
export default MoreText
