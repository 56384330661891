import React from 'react'
import { Trans } from 'react-i18next'

class Loading extends React.Component {
  render() {
    return (
      <div className="d-flex flex-fill flex-column text-center justify-content-center">
        <div>
          <img src="/loading-bubbles.svg" alt="loading bubble" className="img-fluid" style={{ maxWidth: '15rem' }} />
        </div>
        <div className="fs-3">
          <Trans i18nKey="loading.label">Caricamento</Trans>
        </div>
      </div>
    )
  }
}

export default Loading
