import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from 'src/locales/en/translation.json'
import translationIT from 'src/locales/it/translation.json'
import translationFR from 'src/locales/fr/translation.json'
import translationDE from 'src/locales/de/translation.json'

const resources = {
  en: { translation: translationEN },
  it: { translation: translationIT },
  fr: { translation: translationFR },
  de: { translation: translationDE },
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: false,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
