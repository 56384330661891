import React from 'react'
import withRouter from 'src/lib/with_router'
import { Trans } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { Logo } from 'src/components/system_wide/logo'
import SelectLang from 'src/components/system_wide/select_lang'

class Welcome extends React.Component {
  handleShowPassport = () => {
    let { navigate, params } = this.props.router
    navigate(`/digital-passport/${params.rfid}`)
  }

  render() {
    let random_background = Math.floor(Math.random() * (5 - 1 + 1) + 1)
    let has_rfid = this.props?.router.params?.rfid ?? false
    return (
      <>
        <style jsx="true">{`
          .custom_background {
            background: url(/background-${random_background}.jpeg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;
          }
          @media screen and (min-width: 1400px) {
            .custom_background {
              background-position: center top 30%;
            }
          }
        `}</style>
        <div className="d-flex flex-fill flex-column custom_background">
          <Logo className="text-center logo mx-auto" />

          <div className="mt-auto mx-auto text-center">
            <SelectLang maxWidth={'10rem'} type="orizontal" />
            <div className="start-panel mt-3">
              <div className="libre-baskerville-regular with-shadow">
                {has_rfid !== false && (
                  <>
                    <div className="welcome-description">
                      <Trans i18nKey="welcome.start_travel_title">Discover how we made your garment</Trans>
                    </div>
                    <div className="text-center mx-auto mt-3">
                      <Button
                        className="libre-baskerville-bold px-5 py-3"
                        variant="outline-light"
                        onClick={this.handleShowPassport}>
                        <span className="text-uppercase">
                          <Trans i18nKey="welcome.start_button">The journey begins</Trans>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
                {has_rfid === false && <Trans i18nKey="welcome.rfid_not_found">Code not found</Trans>}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Welcome)
