import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AxiosInterceptor } from 'src/lib/axios'
import DpRoute from 'src/components/dp_router'
import 'src/i18n'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/globals.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <AxiosInterceptor>
      <DpRoute />
    </AxiosInterceptor>
  </BrowserRouter>
)
